export const words = [
  ["som", "as", "como"],
  ["jag", "I", "yo"],
  ["hans", "his", "su"],
  ["att", "that", "ese"],
  ["han", "he", "él"],
  ["var", "was", "estaba"],
  ["för", "for", "para"],
  ["på", "on", "en"],
  ["är", "are", "son"],
  ["med", "with", "con"],
  ["de", "they", "ellos"],
  ["vara", "be", "ser"],
  ["vid", "at", "a"],
  ["ett", "one", "uno"],
  ["ha", "have", "tener"],
  ["detta", "this", "esta"],
  ["från", "from", "desde"],
  ["genom", "by", "por"],
  ["het", "hot", "caliente"],
  ["ord", "word", "palabra"],
  ["men", "but", "pero"],
  ["vad", "what", "qué"],
  ["några", "some", "algunos"],
  ["är", "is", "es"],
  ["den", "it", "eso"],
  ["du", "you", "tú"],
  ["eller", "or", "o"],
  ["hade", "had", "tenía"],
  ["den", "the", "la"],
  ["av", "of", "de"],
  ["till", "to", "a"],
  ["och", "and", "y"],
  ["en", "a", "una"],
  ["i", "in", "en"],
  ["vi", "we", "nosotros"],
  ["kan", "can", "puede"],
  ["ut", "out", "afuera"],
  ["andra", "other", "otro"],
  ["var", "were", "fueron"],
  ["vilken", "which", "cual"],
  ["göra", "do", "hacer"],
  ["deras", "their", "su"],
  ["tid", "time", "hora"],
  ["om", "if", "Si"],
  ["kommer", "will", "será"],
  ["hur", "how", "cómo"],
  ["sa", "said", "dijo"],
  ["ett", "an", "un"],
  ["varje", "each", "cada"],
  ["berätta", "tell", "contar"],
  ["gör", "does", "hace"],
  ["uppsättning", "set", "conjunto"],
  ["tre", "three", "Tres"],
  ["vill", "want", "querer"],
  ["luft", "air", "aire"],
  ["väl", "well", "bien"],
  ["också", "also", "además"],
  ["spela", "play", "jugar"],
  ["liten", "small", "pequeña"],
  ["ände", "end", "fin"],
  ["sätta", "put", "poner"],
  ["hem", "home", "casa"],
  ["läsa", "read", "leer"],
  ["sidan", "hand", "mano"],
  ["port", "port", "Puerto"],
  ["stor", "large", "grande"],
  ["stava", "spell", "deletrear"],
  ["lägg", "add", "añadir"],
  ["även", "even", "incluso"],
  ["mark", "land", "tierra"],
  ["här", "here", "aquí"],
  ["must", "must", "debe"],
  ["stor", "big", "grande"],
  ["hög", "high", "alto"],
  ["sådan", "such", "tal"],
  ["följ", "follow", "seguir"],
  ["handling", "act", "acto"],
  ["varför", "why", "por qué"],
  ["be", "ask", "pedir"],
  ["män", "men", "hombres"],
  ["förändring", "change", "cambio"],
  ["gick", "went", "fuimos"],
  ["ljus", "light", "ligero"],
  ["slag", "kind", "tipo"],
  ["off", "off", "apagado"],
  ["behöver", "need", "necesitar"],
  ["hus", "house", "casa"],
  ["bilden", "picture", "imagen"],
  ["försök", "try", "tratar"],
  ["oss", "us", "nosotros"],
  ["igen", "again", "otra vez"],
  ["djur", "animal", "animal"],
  ["punkt", "point", "punto"],
  ["mor", "mother", "madre"],
  ["världen", "world", "mundo"],
  ["nära", "near", "cerca"],
  ["bygga", "build", "construir"],
  ["själv", "self", "yo"],
  ["jord", "earth", "tierra"],
  ["far", "father", "padre"],
  ["någon", "any", "alguna"],
  ["ny", "new", "nuevo"],
  ["arbete", "work", "trabajo"],
  ["delen", "part", "parte"],
  ["ta", "take", "tomar"],
  ["få", "get", "obtener"],
  ["plats", "place", "lugar"],
  ["gjort", "made", "hecho"],
  ["lever", "live", "vivir"],
  ["där", "where", "dónde"],
  ["efter", "after", "después"],
  ["tillbaka", "back", "atrás"],
  ["liten", "little", "pequeño"],
  ["endast", "only", "solamente"],
  ["rund", "round", "redondo"],
  ["människa", "man", "hombre"],
  ["år", "year", "año"],
  ["kom", "came", "vino"],
  ["visar", "show", "espectáculo"],
  ["varje", "every", "cada"],
  ["bra", "good", "bueno"],
  ["mig", "me", "yo"],
  ["ge", "give", "dar"],
  ["vår", "our", "nuestro"],
  ["enligt", "under", "debajo"],
  ["namn", "name", "nombre"],
  ["mycket", "very", "muy"],
  ["genom", "through", "mediante"],
  ["bara", "just", "sólo"],
  ["formulär", "form", "formar"],
  ["meningen", "sentence", "frase"],
  ["bra", "great", "genial"],
  ["tro", "think", "pensar"],
  ["säga", "say", "decir"],
  ["hjälpa", "help", "ayuda"],
  ["låg", "low", "bajo"],
  ["linje", "line", "línea"],
  ["skiljer", "differ", "diferir de"],
  ["tur", "turn", "giro"],
  ["orsak", "cause", "porque"],
  ["mycket", "much", "mucho"],
  ["betyda", "mean", "media"],
  ["före", "before", "antes de"],
  ["drag", "move", "movimiento"],
  ["höger", "right", "Correcto"],
  ["pojke", "boy", "niño"],
  ["gammal", "old", "antiguo"],
  ["alltför", "too", "también"],
  ["samma", "same", "mismo"],
  ["hon", "she", "ella"],
  ["alla", "all", "todos"],
  ["där", "there", "ahí"],
  ["när", "when", "cuando"],
  ["upp", "up", "arriba"],
  ["använda", "use", "utilizar"],
  ["din", "your", "tu"],
  ["sätt", "way", "camino"],
  ["ca", "about", "acerca de"],
  ["många", "many", "muchos"],
  ["sedan", "then", "entonces"],
  ["dem", "them", "ellos"],
  ["skriva", "write", "escribir"],
  ["skulle", "would", "haría"],
  ["liknande", "like", "me gusta"],
  ["så", "so", "asi que"],
  ["dessa", "these", "estas"],
  ["hennes", "her", "su"],
  ["lång", "long", "largo"],
  ["göra", "make", "hacer"],
  ["sak", "thing", "cosa"],
  ["se", "see", "ver"],
  ["honom", "him", "él"],
  ["två", "two", "dos"],
  ["har", "has", "tiene"],
  ["ser", "look", "Mira"],
  ["mer", "more", "Más"],
  ["dag", "day", "día"],
  ["kunde", "could", "podría"],
  ["gå", "go", "ir"],
  ["komma", "come", "ven"],
  ["gjorde", "did", "hizo"],
  ["antal", "number", "número"],
  ["sund", "sound", "sonar"],
  ["nej", "no", "no"],
  ["mest", "most", "más"],
  ["människor", "people", "gente"],
  ["min", "my", "mi"],
  ["över", "over", "terminado"],
  ["vet", "know", "saber"],
  ["vatten", "water", "agua"],
  ["än", "than", "que"],
  ["samtal", "call", "llamada"],
  ["först", "first", "primero"],
  ["som", "who", "quien"],
  ["får", "may", "mayo"],
  ["ner", "down", "abajo"],
  ["sida", "side", "lado"],
  ["varit", "been", "estado"],
  ["nu", "now", "ahora"],
  ["hitta", "find", "encontrar"],
  ["huvud", "head", "cabeza"],
  ["stå", "stand", "estar"],
  ["egen", "own", "propio"],
  ["sida", "page", "página"],
  ["bör", "should", "debería"],
  ["land", "country", "país"],
  ["funnen", "found", "encontró"],
  ["svar", "answer", "responder"],
  ["skola", "school", "colegio"],
  ["växa", "grow", "crecer"],
  ["studie", "study", "estudiar"],
  ["fortfarande", "still", "todavía"],
  ["lära", "learn", "aprender"],
  ["växt", "plant", "planta"],
  ["lock", "cover", "cubrir"],
  ["mat", "food", "comida"],
  ["sol", "sun", "Dom"],
  ["fyra", "four", "cuatro"],
  ["mellan", "between", "Entre"],
  ["tillstånd", "state", "estado"],
  ["hålla", "keep", "mantener"],
  ["öga", "eye", "ojo"],
  ["aldrig", "never", "Nunca"],
  ["sista", "last", "último"],
  ["låt", "let", "dejar"],
  ["trodde", "thought", "pensamiento"],
  ["ort", "city", "ciudad"],
  ["träd", "tree", "árbol"],
  ["korsa", "cross", "cruzar"],
  ["gård", "farm", "granja"],
  ["hård", "hard", "difícil"],
  ["start", "start", "comienzo"],
  ["kanske", "might", "podría"],
  ["berättelse", "story", "historia"],
  ["sågen", "saw", "Sierra"],
  ["långt", "far", "lejos"],
  ["hav", "sea", "mar"],
  ["rita", "draw", "dibujar"],
  ["vänster", "left", "izquierda"],
  ["sen", "late", "tarde"],
  ["köra", "run", "correr"],
  ["inte", "don’t", "no"],
  ["medan", "while", "mientras"],
  ["tryck", "press", "prensa"],
  ["nära", "close", "cerrar"],
  ["natt", "night", "noche"],
  ["verklig", "real", "real"],
  ["liv", "life", "vida"],
  ["några", "few", "pocos"],
  ["norr", "north", "norte"],
  ["boken", "book", "libro"],
  ["bära", "carry", "llevar"],
  ["tog", "took", "tomó"],
  ["vetenskap", "science", "ciencia"],
  ["äta", "eat", "comer"],
  ["rum", "room", "habitación"],
  ["vän", "friend", "amigo"],
  ["började", "began", "empezó"],
  ["idé", "idea", "idea"],
  ["fisk", "fish", "pez"],
  ["berg", "mountain", "montaña"],
  ["stoppa", "stop", "detener"],
  ["en gång", "once", "una vez"],
  ["bas", "base", "base"],
  ["höra", "hear", "oír"],
  ["häst", "horse", "caballo"],
  ["snitt", "cut", "cortar"],
  ["säker", "sure", "Por supuesto"],
  ["klocka", "watch", "reloj"],
  ["färg", "color", "color"],
  ["ansikte", "face", "cara"],
  ["trä", "wood", "madera"],
  ["huvud", "main", "principal"],
  ["öppen", "open", "abierto"],
  ["tyckas", "seem", "parecer"],
  ["tillsammans", "together", "juntos"],
  ["nästa", "next", "siguiente"],
  ["vit", "white", "blanco"],
  ["barn", "children", "niños"],
  ["börja", "begin", "empezar"],
  ["fick", "got", "tiene"],
  ["gå", "walk", "caminar"],
  ["exempel", "example", "ejemplo"],
  ["lindra", "ease", "facilitar"],
  ["papper", "paper", "papel"],
  ["grupp", "group", "grupo"],
  ["alltid", "always", "siempre"],
  ["musik", "music", "música"],
  ["de som", "those", "aquellos"],
  ["båda", "both", "ambos"],
  ["märke", "mark", "marca"],
  ["ofta", "often", "a menudo"],
  ["bokstav", "letter", "carta"],
  ["tills", "until", "hasta"],
  ["mil", "mile", "milla"],
  ["flod", "river", "río"],
  ["bil", "car", "coche"],
  ["fot", "feet", "pies"],
  ["vård", "care", "cuidado"],
  ["andra", "second", "segundo"],
  ["tillräckligt", "enough", "suficiente"],
  ["vanlig", "plain", "llanura"],
  ["flicka", "girl", "niña"],
  ["vanligt", "usual", "usual"],
  ["ung", "young", "joven"],
  ["klar", "ready", "Listo"],
  ["ovan", "above", "encima"],
  ["någonsin", "ever", "siempre"],
  ["röd", "red", "rojo"],
  ["lista", "list", "lista"],
  ["though", "though", "aunque"],
  ["känna", "feel", "sensación"],
  ["diskussion", "talk", "hablar"],
  ["fågel", "bird", "pájaro"],
  ["snart", "soon", "pronto"],
  ["kropp", "body", "cuerpo"],
  ["hund", "dog", "perro"],
  ["familj", "family", "familia"],
  ["direkt", "direct", "directo"],
  ["pose", "pose", "actitud"],
  ["lämna", "leave", "salir"],
  ["sång", "song", "canción"],
  ["mäta", "measure", "medida"],
  ["dörr", "door", "puerta"],
  ["produkt", "product", "producto"],
  ["svart", "black", "negro"],
  ["kort", "short", "corto"],
  ["siffran", "numeral", "número"],
  ["klass", "class", "clase"],
  ["vind", "wind", "viento"],
  ["fråga", "question", "pregunta"],
  ["hända", "happen", "ocurrir"],
  ["fullständig", "complete", "completar"],
  ["skepp", "ship", "enviar"],
  ["område", "area", "zona"],
  ["halv", "half", "mitad"],
  ["vagga", "rock", "rock"],
  ["för", "order", "orden"],
  ["eld", "fire", "fuego"],
  ["söder", "south", "sur"],
  ["problemet", "problem", "problema"],
  ["stycke", "piece", "trozo"],
  ["sa", "told", "dicho"],
  ["visste", "knew", "sabía"],
  ["passera", "pass", "pasar"],
  ["sedan", "since", "desde"],
  ["topp", "top", "parte superior"],
  ["hela", "whole", "todo"],
  ["kung", "king", "Rey"],
  ["gata", "street", "calle"],
  ["tum", "inch", "pulgada"],
  ["multiplicera", "multiply", "multiplicar"],
  ["ingenting", "nothing", "nada"],
  ["Naturligtvis", "course", "curso"],
  ["bo", "stay", "permanecer"],
  ["hjul", "wheel", "rueda"],
  ["fullständig", "full", "completo"],
  ["kraft", "force", "fuerza"],
  ["blå", "blue", "azul"],
  ["objektet", "object", "objeto"],
  ["besluta", "decide", "decidir"],
  ["yta", "surface", "superficie"],
  ["djup", "deep", "profundo"],
  ["månen", "moon", "Luna"],
  ["ö", "island", "isla"],
  ["fot", "foot", "pie"],
  ["systemet", "system", "sistema"],
  ["upptagen", "busy", "ocupado"],
  ["testet", "test", "prueba"],
  ["register", "record", "grabar"],
  ["båt", "boat", "bote"],
  ["gemensam", "common", "común"],
  ["guld", "gold", "oro"],
  ["möjlig", "possible", "posible"],
  ["plan", "plane", "avión"],
  ["stead", "stead", "lugar"],
  ["torka", "dry", "seco"],
  ["undra", "wonder", "preguntarse"],
  ["skratt", "laugh", "risa"],
  ["tusen", "thousand", "mil"],
  ["sedan", "ago", "hace"],
  ["ran", "ran", "corrió"],
  ["ta", "check", "comprobar"],
  ["spel", "game", "juego"],
  ["form", "shape", "forma"],
  ["likställa", "equate", "equiparar"],
  ["het", "hot", "caliente"],
  ["miss", "miss", "perder"],
  ["bringas", "brought", "trajo"],
  ["hetta", "heat", "calor"],
  ["snö", "snow", "nieve"],
  ["däcket", "tire", "neumático"],
  ["bringa", "bring", "traer"],
  ["ja", "yes", "sí"],
  ["avlägsen", "distant", "distante"],
  ["fylla", "fill", "llenar"],
  ["öst", "east", "este"],
  ["måla", "paint", "pintar"],
  ["språk", "language", "idioma"],
  ["bland", "among", "entre"],
  ["enhet", "unit", "unidad"],
  ["kraft", "power", "poder"],
  ["stad", "town", "pueblo"],
  ["fin", "fine", "multa"],
  ["viss", "certain", "cierto"],
  ["flyga", "fly", "volar"],
  ["falla", "fall", "otoño"],
  ["bly", "lead", "dirigir"],
  ["rop", "cry", "llorar"],
  ["mörk", "dark", "oscuro"],
  ["maskin", "machine", "máquina"],
  ["anteckning", "note", "Nota"],
  ["vänta", "wait", "Espere"],
  ["planen", "plan", "plan"],
  ["figur", "figure", "figura"],
  ["stjärna", "star", "estrella"],
  ["låda", "box", "caja"],
  ["subst", "noun", "sustantivo"],
  ["fält", "field", "campo"],
  ["resten", "rest", "descanso"],
  ["korrekt", "correct", "correcto"],
  ["kunna", "able", "poder"],
  ["pund", "pound", "libra"],
  ["klar", "done", "hecho"],
  ["skönhet", "beauty", "belleza"],
  ["enhet", "drive", "conducir"],
  ["stod", "stood", "destacado"],
  ["innehålla", "contain", "Contiene"],
  ["främre", "front", "frente"],
  ["undervisa", "teach", "enseñar"],
  ["vecka", "week", "semana"],
  ["slutlig", "final", "final"],
  ["gav", "gave", "dio"],
  ["grön", "green", "verde"],
  ["ack", "oh", "Oh"],
  ["sammanfattning", "quick", "rápido"],
  ["utveckla", "develop", "desarrollar"],
  ["ocean", "ocean", "Oceano"],
  ["varm", "warm", "calentar"],
  ["fri", "free", "gratis"],
  ["minut", "minute", "minuto"],
  ["stark", "strong", "fuerte"],
  ["speciell", "special", "especial"],
  ["sinne", "mind", "mente"],
  ["bakom", "behind", "detrás"],
  ["klar", "clear", "claro"],
  ["svans", "tail", "cola"],
  ["producera", "produce", "Produce"],
  ["faktum", "fact", "hecho"],
  ["space", "space", "espacio"],
  ["hört", "heard", "escuchado"],
  ["bäst", "best", "mejor"],
  ["timme", "hour", "hora"],
  ["bättre", "better", "mejor"],
  ["SANT", "true", "CIERTO"],
  ["under", "during", "durante"],
  ["hundra", "hundred", "cien"],
  ["fem", "five", "cinco"],
  ["minnas", "remember", "recuerda"],
  ["steg", "step", "paso"],
  ["tidig", "early", "temprano"],
  ["håll", "hold", "sostener"],
  ["väster", "west", "Oeste"],
  ["jord", "ground", "suelo"],
  ["intresse", "interest", "interesar"],
  ["nå", "reach", "alcanzar"],
  ["snabb", "fast", "rápido"],
  ["verb", "verb", "verbo"],
  ["sjunger", "sing", "canta"],
  ["lyssna", "listen", "escucha"],
  ["sex", "six", "seis"],
  ["tabell", "table", "mesa"],
  ["resor", "travel", "viaje"],
  ["mindre", "less", "Menos"],
  ["morgon", "morning", "Mañana"],
  ["tio", "ten", "diez"],
  ["enkel", "simple", "sencillo"],
  ["flera", "several", "varios"],
  ["vokal", "vowel", "vocal"],
  ["mot", "toward", "hacia"],
  ["krig", "war", "guerra"],
  ["låg", "lay", "laico"],
  ["mot", "against", "en contra"],
  ["mönster", "pattern", "modelo"],
  ["långsam", "slow", "lento"],
  ["centrum", "center", "centrar"],
  ["kärlek", "love", "amor"],
  ["personen", "person", "persona"],
  ["pengar", "money", "dinero"],
  ["tjäna", "serve", "servir"],
  ["synas", "appear", "Aparecer"],
  ["väg", "road", "la carretera"],
  ["karta", "map", "mapa"],
  ["regn", "rain", "lluvia"],
  ["regel", "rule", "regla"],
  ["regera", "govern", "gobernar"],
  ["dra", "pull", "Halar"],
  ["förkylning", "cold", "frío"],
  ["meddelande", "notice", "darse cuenta"],
  ["röst", "voice", "voz"],
  ["energi", "energy", "energía"],
  ["jakt", "hunt", "cazar"],
  ["trolig", "probable", "probable"],
  ["bädd", "bed", "cama"],
  ["bror", "brother", "hermano"],
  ["ägg", "egg", "huevo"],
  ["ritt", "ride", "paseo"],
  ["cell", "cell", "célula"],
  ["tro", "believe", "creer"],
  ["kanske", "perhaps", "quizás"],
  ["plocka", "pick", "recoger"],
  ["plötslig", "sudden", "repentino"],
  ["räkna", "count", "contar"],
  ["kvadrat", "square", "cuadrado"],
  ["anledningen", "reason", "razón"],
  ["längd", "length", "longitud"],
  ["representerar", "represent", "representar"],
  ["teknikområdet", "art", "Art º"],
  ["ämne", "subject", "tema"],
  ["region", "region", "región"],
  ["storlek", "size", "tamaño"],
  ["varierar", "vary", "variar"],
  ["sedimentera", "settle", "resolver"],
  ["tala", "speak", "hablar"],
  ["vikt", "weight", "peso"],
  ["allmän", "general", "general"],
  ["is", "ice", "hielo"],
  ["materia", "matter", "importar"],
  ["cirkel", "circle", "circulo"],
  ["par", "pair", "par"],
  ["inkluderar", "include", "incluir"],
  ["divide", "divide", "dividir"],
  ["stavelse", "syllable", "sílaba"],
  ["kände", "felt", "sintió"],
  ["stors", "grand", "grandioso"],
  ["boll", "ball", "bola"],
  ["ännu", "yet", "todavía"],
  ["våg", "wave", "ola"],
  ["släpp", "drop", "soltar"],
  ["hjärta", "heart", "corazón"],
  ["jag", "am", "a.m"],
  ["föreliggande", "present", "presente"],
  ["tung", "heavy", "pesado"],
  ["dans", "dance", "danza"],
  ["motor", "engine", "motor"],
  ["ställning", "position", "posición"],
  ["beväpnar", "arm", "brazo"],
  ["bred", "wide", "amplio"],
  ["segel", "sail", "vela"],
  ["materialet", "material", "material"],
  ["fraktion", "fraction", "fracción"],
  ["skog", "forest", "bosque"],
  ["sitta", "sit", "sentar"],
  ["ras", "race", "carrera"],
  ["fönster", "window", "ventana"],
  ["lagra", "store", "almacenar"],
  ["sommar", "summer", "verano"],
  ["tåg", "train", "entrenar"],
  ["sömn", "sleep", "dormir"],
  ["bevisa", "prove", "probar"],
  ["ensam", "lone", "solitario"],
  ["ben", "leg", "pierna"],
  ["övning", "exercise", "ejercicio"],
  ["vägg", "wall", "pared"],
  ["fångst", "catch", "captura"],
  ["fäste", "mount", "montar"],
  ["vill", "wish", "deseo"],
  ["himmelsblå", "sky", "cielo"],
  ["ombord", "board", "tablero"],
  ["glädje", "joy", "alegría"],
  ["vinter", "winter", "invierno"],
  ["satellit", "sat", "Sab"],
  ["skriven", "written", "escrito"],
  ["vild", "wild", "salvaje"],
  ["instrumentet", "instrument", "instrumento"],
  ["hålls", "kept", "mantenido"],
  ["glas", "glass", "vaso"],
  ["gräs", "grass", "hierba"],
  ["ko", "cow", "vaca"],
  ["jobb", "job", "trabajo"],
  ["kanten", "edge", "borde"],
  ["tecken", "sign", "firmar"],
  ["besök", "visit", "visitar"],
  ["förbi", "past", "pasado"],
  ["mjuk", "soft", "suave"],
  ["kul", "fun", "divertido"],
  ["ljust", "bright", "brillante"],
  ["gas", "gas", "gas"],
  ["väder", "weather", "clima"],
  ["månad", "month", "mes"],
  ["miljoner", "million", "millón"],
  ["bära", "bear", "oso"],
  ["yta", "finish", "terminar"],
  ["lycklig", "happy", "feliz"],
  ["hoppas", "hope", "esperanza"],
  ["blomma", "flower", "flor"],
  ["kläder", "clothe", "vestir"],
  ["främmande", "strange", "extraño"],
  ["borta", "gone", "ido"],
  ["handel", "trade", "comercio"],
  ["melodi", "melody", "melodía"],
  ["resa", "trip", "viaje"],
  ["kontor", "office", "oficina"],
  ["motta", "receive", "recibir"],
  ["rad", "row", "fila"],
  ["mun", "mouth", "boca"],
  ["exakta", "exact", "exacto"],
  ["symbol", "symbol", "símbolo"],
  ["dö", "die", "morir"],
  ["minst", "least", "menos"],
  ["besvär", "trouble", "problema"],
  ["rop", "shout", "gritar"],
  ["utom", "except", "excepto"],
  ["skrev", "wrote", "escribió"],
  ["frö", "seed", "semilla"],
  ["ton", "tone", "tono"],
  ["ansluta", "join", "unirse"],
  ["föreslår", "suggest", "sugerir"],
  ["ren", "clean", "limpiar"],
  ["paus", "break", "descanso"],
  ["damen", "lady", "dama"],
  ["gård", "yard", "yarda"],
  ["stiga", "rise", "subir"],
  ["dålig", "bad", "malo"],
  ["smäll", "blow", "soplo"],
  ["olja", "oil", "petróleo"],
  ["blod", "blood", "sangre"],
  ["Rör", "touch", "toque"],
  ["växte", "grew", "creció"],
  ["cent", "cent", "centavo"],
  ["blanda", "mix", "mezcla"],
  ["lag", "team", "equipo"],
  ["tråd", "wire", "cable"],
  ["kostnad", "cost", "costo"],
  ["förlorat", "lost", "perdió"],
  ["brun", "brown", "marrón"],
  ["slitage", "wear", "vestir"],
  ["trädgård", "garden", "jardín"],
  ["lika", "equal", "igual"],
  ["skickat", "sent", "expedido"],
  ["välja", "choose", "escoger"],
  ["föll", "fell", "cayó"],
  ["passa", "fit", "ajuste"],
  ["flöde", "flow", "fluir"],
  ["verkligt", "fair", "justa"],
  ["bank", "bank", "banco"],
  ["samla", "collect", "recoger"],
  ["spara", "save", "salvar"],
  ["kontroll", "control", "controlar"],
  ["decimala", "decimal", "decimal"],
  ["örat", "ear", "oreja"],
  ["annars", "else", "más"],
  ["ganska", "quite", "bastante"],
  ["bröt", "broke", "rompió"],
  ["fall", "case", "caso"],
  ["mitten", "middle", "medio"],
  ["döda", "kill", "matar"],
  ["son", "son", "hijo"],
  ["sjö", "lake", "lago"],
  ["ögonblick", "moment", "momento"],
  ["skala", "scale", "escala"],
  ["högt", "loud", "ruidoso"],
  ["fjäder", "spring", "primavera"],
  ["observera", "observe", "observar"],
  ["barn", "child", "niño"],
  ["rak", "straight", "Derecho"],
  ["konsonant", "consonant", "consonante"],
  ["nation", "nation", "nación"],
  ["ordbok", "dictionary", "diccionario"],
  ["mjölk", "milk", "Leche"],
  ["hastighet", "speed", "velocidad"],
  ["metod", "method", "método"],
  ["organ", "organ", "Organo"],
  ["betala", "pay", "paga"],
  ["okänd", "age", "años"],
  ["sektion", "section", "sección"],
  ["klänning", "dress", "vestido"],
  ["moln", "cloud", "nube"],
  ["överraskning", "surprise", "sorpresa"],
  ["ro", "quiet", "tranquilo"],
  ["sten", "stone", "piedra"],
  ["liten", "tiny", "minúsculo"],
  ["klättra", "climb", "subida"],
  ["sval", "cool", "guay"],
  ["konstruktion", "design", "diseño"],
  ["dålig", "poor", "pobre"],
  ["mycket", "lot", "mucho"],
  ["experiment", "experiment", "experimentar"],
  ["botten", "bottom", "fondo"],
  ["nyckel", "key", "llave"],
  ["järn", "iron", "planchar"],
  ["singel", "single", "soltero"],
  ["pinne", "stick", "palo"],
  ["platt", "flat", "plano"],
  ["tjugo", "twenty", "veinte"],
  ["hud", "skin", "piel"],
  ["le", "smile", "sonreír"],
  ["veck", "crease", "pliegue"],
  ["hål", "hole", "agujero"],
  ["hoppa", "jump", "saltar"],
  ["barn", "baby", "bebé"],
  ["åtta", "eight", "ocho"],
  ["by", "village", "pueblo"],
  ["möts", "meet", "reunirse"],
  ["rot", "root", "raíz"],
  ["köpa", "buy", "comprar"],
  ["höja", "raise", "aumento"],
  ["lösa", "solve", "resolver"],
  ["metall", "metal", "metal"],
  ["huruvida", "whether", "ya sea"],
  ["tryck", "push", "empujar"],
  ["sju", "seven", "Siete"],
  ["punkt", "paragraph", "párrafo"],
  ["tredje", "third", "tercero"],
  ["skall", "shall", "deberá"],
  ["vänt", "held", "retenida"],
  ["hår", "hair", "pelo"],
  ["beskriva", "describe", "describir"],
  ["kock", "cook", "cocinar"],
  ["våningen", "floor", "piso"],
  ["antingen", "either", "ya sea"],
  ["resultat", "result", "resultado"],
  ["bränna", "burn", "quemar"],
  ["kulle", "hill", "colina"],
  ["säker", "safe", "seguro"],
  ["cat", "cat", "gato"],
  ["talet", "century", "siglo"],
  ["överväga", "consider", "considerar"],
  ["typ", "type", "tipo"],
  ["lag", "law", "ley"],
  ["bitars", "bit", "poco"],
  ["kust", "coast", "costa"],
  ["kopia", "copy", "dupdo"],
  ["fras", "phrase", "frase"],
  ["tyst", "silent", "silencio"],
  ["tall", "tall", "alto"],
  ["sand", "sand", "arena"],
  ["jord", "soil", "suelo"],
  ["rulle", "roll", "rodar"],
  ["temperatur", "temperature", "temperatura"],
  ["finger", "finger", "dedo"],
  ["industrin", "industry", "industria"],
  ["värde", "value", "valor"],
  ["slagsmål", "fight", "lucha"],
  ["lie", "lie", "mentira"],
  ["slå", "beat", "golpear"],
  ["excitera", "excite", "excitar"],
  ["naturlig", "natural", "natural"],
  ["vy", "view", "ver"],
  ["känsla", "sense", "sentido"],
  ["kapital", "capital", "capital"],
  ["kommer inte", "won’t", "no va a"],
  ["stol", "chair", "silla"],
  ["fara", "danger", "peligro"],
  ["frukt", "fruit", "Fruta"],
  ["rik", "rich", "Rico"],
  ["tjock", "thick", "grueso"],
  ["soldat", "soldier", "soldado"],
  ["processen", "process", "proceso"],
  ["fungera", "operate", "funcionar"],
  ["praktiken", "practice", "práctica"],
  ["separat", "separate", "separar"],
  ["svårt", "difficult", "difícil"],
  ["läkare", "doctor", "doctor"],
  ["vänligen", "please", "Por favor"],
  ["skydda", "protect", "proteger"],
  ["middagstid", "noon", "mediodía"],
  ["gröda", "crop", "cultivo"],
  ["moderna", "modern", "moderno"],
  ["elementet", "element", "elemento"],
  ["hit", "hit", "golpear"],
  ["studenten", "student", "estudiante"],
  ["hörn", "corner", "esquina"],
  ["parti", "party", "partido"],
  ["försörjning", "supply", "suministro"],
  ["vars", "whose", "cuyo"],
  ["lokalisera", "locate", "localizar"],
  ["ring", "ring", "anillo"],
  ["karaktär", "character", "personaje"],
  ["insekt", "insect", "insecto"],
  ["fångad", "caught", "atrapado"],
  ["period", "period", "período"],
  ["indikerar", "indicate", "indicar"],
  ["radio", "radio", "radio"],
  ["talade", "spoke", "habló"],
  ["atomen", "atom", "átomo"],
  ["humant", "human", "humano"],
  ["historia", "history", "historia"],
  ["effekt", "effect", "efecto"],
  ["elektrisk", "electric", "eléctrico"],
  ["förvänta", "expect", "esperar"],
  ["ben", "bone", "hueso"],
  ["skena", "rail", "carril"],
  ["föreställa sig", "imagine", "imagina"],
  ["tillhandahålla", "provide", "proporcionar"],
  ["enas", "agree", "de acuerdo"],
  ["sålunda", "thus", "así"],
  ["mild", "gentle", "amable"],
  ["kvinna", "woman", "mujer"],
  ["kapten", "captain", "capitán"],
  ["gissa", "guess", "adivinar"],
  ["nödvändig", "necessary", "necesario"],
  ["skarp", "sharp", "agudo"],
  ["vinge", "wing", "ala"],
  ["skapa", "create", "crear"],
  ["granne", "neighbor", "vecino"],
  ["tvätt", "wash", "lavar"],
  ["slagträ", "bat", "murciélago"],
  ["snarare", "rather", "más bien"],
  ["publiken", "crowd", "multitud"],
  ["majs", "corn", "maíz"],
  ["jämföra", "compare", "comparar"],
  ["dikt", "poem", "poema"],
  ["sträng", "string", "cuerda"],
  ["klocka", "bell", "campana"],
  ["bero", "depend", "depender"],
  ["kött", "meat", "carne"],
  ["gnida", "rub", "frotar"],
  ["röret", "tube", "tubo"],
  ["känd", "famous", "famoso"],
  ["dollarn", "dollar", "dólar"],
  ["ström", "stream", "corriente"],
  ["rädsla", "fear", "temor"],
  ["syn", "sight", "visión"],
  ["tunn", "thin", "Delgado"],
  ["triangel", "triangle", "triángulo"],
  ["planet", "planet", "planeta"],
  ["skynda", "hurry", "prisa"],
  ["chef", "chief", "jefe"],
  ["koloni", "colony", "colonia"],
  ["klocka", "clock", "reloj"],
  ["gruva", "mine", "mía"],
  ["slips", "tie", "Corbata"],
  ["ange", "enter", "entrar"],
  ["större", "major", "mayor"],
  ["färsk", "fresh", "Fresco"],
  ["sök", "search", "buscar"],
  ["skicka", "send", "enviar"],
  ["gul", "yellow", "amarillo"],
  ["pistol", "gun", "pistola"],
  ["tillåta", "allow", "permitir"],
  ["tryck", "print", "impresión"],
  ["död", "dead", "muerto"],
  ["fläck", "spot", "lugar"],
  ["öken", "desert", "Desierto"],
  ["kostym", "suit", "traje"],
  ["ström", "current", "corriente"],
  ["lift", "lift", "levantar"],
  ["ros", "rose", "Rosa"],
  ["anländer", "arrive", "llegar"],
  ["mästare", "master", "dominar"],
  ["spår", "track", "pista"],
  ["förälder", "parent", "padre"],
  ["stranden", "shore", "apuntalar"],
  ["delning", "division", "división"],
  ["ark", "sheet", "hoja"],
  ["substans", "substance", "sustancia"],
  ["gynna", "favor", "favor"],
  ["ansluta", "connect", "conectar"],
  ["inlägg", "post", "enviar"],
  ["spendera", "spend", "gastar"],
  ["ackord", "chord", "acorde"],
  ["fett", "fat", "gordo"],
  ["glad", "glad", "alegre"],
  ["original", "original", "original"],
  ["andel", "share", "compartir"],
  ["station", "station", "estación"],
  ["pappa", "dad", "papá"],
  ["bröd", "bread", "un pan"],
  ["debitera", "charge", "cargar"],
  ["ordentlig", "proper", "apropiado"],
  ["baren", "bar", "bar"],
  ["erbjudandet", "offer", "oferta"],
  ["segment", "segment", "segmento"],
  ["slav", "slave", "esclavo"],
  ["anka", "duck", "Pato"],
  ["omedelbar", "instant", "instante"],
  ["marknad", "market", "mercado"],
  ["grad", "degree", "la licenciatura"],
  ["befolka", "populate", "poblar"],
  ["fågelunge", "chick", "polluelo"],
  ["kära", "dear", "querido"],
  ["fiende", "enemy", "enemigo"],
  ["svara", "reply", "respuesta"],
  ["dryck", "drink", "beber"],
  ["inträffa", "occur", "ocurrir"],
  ["stöd", "support", "apoyo"],
  ["tal", "speech", "habla"],
  ["natur", "nature", "naturaleza"],
  ["intervall", "range", "distancia"],
  ["ånga", "steam", "vapor"],
  ["rörelse", "motion", "movimiento"],
  ["bana", "path", "camino"],
  ["vätska", "liquid", "líquido"],
  ["logga", "log", "Iniciar sesión"],
  ["innebar", "meant", "significaba"],
  ["kvoten", "quotient", "cociente"],
  ["tänder", "teeth", "dientes"],
  ["skal", "shell", "cáscara"],
  ["hals", "neck", "cuello"],
  ["syre", "oxygen", "oxígeno"],
  ["socker", "sugar", "azúcar"],
  ["död", "death", "muerte"],
  ["ganska", "pretty", "bonita"],
  ["Förmåga", "skill", "habilidad"],
  ["kvinnor", "women", "mujer"],
  ["säsong", "season", "temporada"],
  ["lösning", "solution", "solución"],
  ["magnet", "magnet", "imán"],
  ["silver", "silver", "plata"],
  ["tack", "thank", "gracias"],
  ["gren", "branch", "rama"],
  ["matchen", "match", "partido"],
  ["suffixet", "suffix", "sufijo"],
  ["speciellt", "especially", "especialmente"],
  ["fig", "fig", "higo"],
  ["rädd", "afraid", "temeroso"],
  ["enormt", "huge", "enorme"],
  ["syster", "sister", "hermana"],
  ["stål", "steel", "acero"],
  ["diskutera", "discuss", "discutir"],
  ["framåt", "forward", "adelante"],
  ["liknande", "similar", "similar"],
  ["guide", "guide", "guía"],
  ["erfarenhet", "experience", "experiencia"],
  ["poäng", "score", "Puntuación"],
  ["äpple", "apple", "manzana"],
  ["köpt", "bought", "compró"],
  ["ledde", "led", "LED"],
  ["tonhöjd", "pitch", "tono"],
  ["kappa", "coat", "capa"],
  ["massa", "mass", "masa"],
  ["kort", "card", "tarjeta"],
  ["band", "band", "banda"],
  ["rep", "rope", "cuerda"],
  ["halka", "slip", "resbalón"],
  ["win", "win", "ganar"],
  ["dröm", "dream", "sueño"],
  ["kväll", "evening", "noche"],
  ["tillstånd", "condition", "condición"],
  ["foder", "feed", "alimentar"],
  ["verktyg", "tool", "herramienta"],
  ["totalt", "total", "total"],
  ["grundläggande", "basic", "BASIC"],
  ["lukt", "smell", "oler"],
  ["dal", "valley", "Valle"],
  ["eller", "nor", "ni"],
  ["dubbel", "double", "doble"],
  ["säte", "seat", "asiento"],
  ["fortsätta", "continue", "continuar"],
  ["blocket", "block", "bloquear"],
  ["diagram", "chart", "gráfico"],
  ["keps", "hat", "sombrero"],
  ["sälja", "sell", "vender"],
  ["framgång", "success", "éxito"],
  ["företaget", "company", "empresa"],
  ["subtrahera", "subtract", "sustraer"],
  ["händelse", "event", "evento"],
  ["särskilt", "particular", "especial"],
  ["affär", "deal", "acuerdo"],
  ["simma", "swim", "nadar"],
  ["term", "term", "término"],
  ["motsatt", "opposite", "opuesto"],
  ["fru", "wife", "esposa"],
  ["sko", "shoe", "zapato"],
  ["skuldra", "shoulder", "hombro"],
  ["spridning", "spread", "untado"],
  ["ordna", "arrange", "organizar"],
  ["läger", "camp", "acampar"],
  ["uppfinna", "invent", "inventar"],
  ["bomull", "cotton", "algodón"],
  ["Född", "born", "nacido"],
  ["bestämma", "determine", "determinar"],
  ["quart", "quart", "cuarto de galón"],
  ["nio", "nine", "nueve"],
  ["lastbil", "truck", "camión"],
  ["buller", "noise", "ruido"],
  ["nivå", "level", "nivel"],
  ["chans", "chance", "oportunidad"],
  ["samla", "gather", "reunir"],
  ["butik", "shop", "tienda"],
  ["sträcka", "stretch", "tramo"],
  ["kasta", "throw", "lanzar"],
  ["glans", "shine", "brillar"],
  ["egenskap", "property", "propiedad"],
  ["kolonn", "column", "columna"],
  ["molekyl", "molecule", "molécula"],
  ["välj", "select", "seleccionar"],
  ["fel", "wrong", "incorrecto"],
  ["grå", "gray", "gris"],
  ["upprepning", "repeat", "repetir"],
  ["kräva", "require", "exigir"],
  ["bred", "broad", "ancho"],
  ["förbereda", "prepare", "preparar"],
  ["salt", "salt", "sal"],
  ["näsa", "nose", "nariz"],
  ["plural", "plural", "plural"],
  ["ilska", "anger", "enfado"],
  ["krav", "claim", "Reclamación"],
  ["kontinent", "continent", "continente"]
];
